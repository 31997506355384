import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import WebinarVideo from "./../screens/webinar-replay/webinar-video"
import BodyLanding from "./../screens/webinar-replay/2022-09-27-trent/body-landing"

const TrentView = () => {
	return (
		<React.Fragment>
			<WebinarVideo
				videoTitle="Importance of Rapid STI Testing for Sexually Active Adolescent Girls"
				videoDisplayTitle="Importance of Rapid STI Testing for Sexually Active Adolescent Girls"
				vimeoLink="https://player.vimeo.com/video/755611039?h=d57cf4d125"
				placeholderImage="2022-09-27-trent-webinar-replay.jpg"
				localStorageID="2022-09-27-trent-webinar-replay"
				pageSlug="/webinar-replay/2022-09-27-trent/"
			/>
			<BodyLanding pageName="download" localStorageID="2022-09-27-trent-webinar-replay" />
		</React.Fragment>
	)
}

export default TrentView

export const Head = () => (
  <Seo
	title="Webinar Replay: Importance of Rapid STI Testing for Sexually Active Adolescent Girls"
	description="Presenter Dr. Maria Trent discusses STI testing and education strategies for sexually active girls."
	image="/meta/webinar-trent-2022-09-27.jpg"
  />
)
